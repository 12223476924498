import React from "react";
import { connect } from "react-redux";
import { churchId, l10n, disclaimer } from "../../../common/constants";
import { Button, Modal } from "react-bootstrap";
import { IsEmpty } from "../../../common/helpers";

const location =
    !IsEmpty(localStorage.getItem("exclusive")) &&
    localStorage.getItem("exclusive") === "aogrun"
        ? "aogrun"
        : !IsEmpty(localStorage.getItem("exclusive")) &&
          localStorage.getItem("exclusive") === "gmsrunmanado"
        ? "gmsrunmanado"
        : !IsEmpty(localStorage.getItem("exclusive")) &&
          localStorage.getItem("exclusive") === "aogfindingmajor"
        ? "aogfindingmajor"
        : !IsEmpty(localStorage.getItem("exclusive")) &&
          localStorage.getItem("exclusive") === "aogbootcampteen"
        ? "aogbootcampteen"
        : !IsEmpty(localStorage.getItem("exclusive")) &&
          localStorage.getItem("exclusive") === "aogbootcampyouth"
        ? "aogbootcampyouth"
        : localStorage.getItem("church_id") === churchId.Singapore
        ? "Singapore"
        : localStorage.getItem("church_id") === churchId.Sydney
        ? "Sydney"
        : localStorage.getItem("church_id") === churchId.JakartaBarat
        ? "Jabodetabek"
        : localStorage.getItem("church_id") === churchId.Sidoarjo
        ? "Sidoarjo"
        : localStorage.getItem("church_id") === churchId.Denpasar
        ? "Denpasar"
        : localStorage.getItem("church_id") === churchId.SurabayaBarat
        ? "SurabayaBarat"
        : localStorage.getItem("church_id") === churchId.Binjai
        ? "Binjai"
        : localStorage.getItem("church_id") === churchId.Medan
        ? "Medan"
        : "National";

function ModalDisclaimer(props) {
    return (
        <>
            <Modal show={props.welcomeShowModal}>
                <Modal.Body>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: disclaimer[location].welcome,
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={props.welcomeHandleClose}
                    >
                        {l10n["next"][props.language]}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={props.firstShowModal}>
                <Modal.Header>
                    <Modal.Title>Disclaimer</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: disclaimer[location].body,
                        }}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <div
                        className="row d-flex align-items-center"
                        style={agreementStyle}
                    >
                        <div className="col-2">
                            <input
                                type="checkbox"
                                name="agreement"
                                value={props.agreement}
                                checked={props.agreement}
                                onChange={props.onCheckAgreement}
                                style={agreementCheckStyle}
                            />
                        </div>
                        <div
                            className="col-10"
                            onClick={props.onCheckAgreement}
                            value={props.agreement}
                        >
                            {l10n["acknowledge"][props.language]}
                        </div>
                    </div>
                    <Button
                        variant="secondary"
                        onClick={props.firstHandleReject}
                    >
                        {l10n["cancel"][props.language]}
                    </Button>
                    <Button variant="primary" onClick={props.firstHandleClose}>
                        {l10n["agree"][props.language]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

// Styles
const agreementStyle = {
    marginTop: "24px",
    marginBottom: "24px",
};

const agreementCheckStyle = {
    width: "24px",
    height: "24px",
    cursor: "pointer",
};

// Redux
const mapStateToProps = (state) => ({
    language: state.userReducer.language,
});

export default connect(mapStateToProps)(ModalDisclaimer);
