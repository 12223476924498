import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getTimeWithoutTimezone } from '../../common/helpers';

export class OperatorEventSubItem extends Component {
  render() {
    // eslint-disable-next-line
    const { id, name, room, start_date, end_date, quantity } = this.props.eventSub;
    return (
      <div className="col-6" style={colStyle}>
        <a href={`/operator/scan/${this.props.event.code}/${id}`} className="btn" style={{ ...itemStyle, border: `solid 5px #${this.props.eventSub.ticket_color_hex}` }}>
          <div style={nameStyle}>{name}</div>
          <div style={roomStyle}>{room.name}</div>
          <div style={infoStyle}>{getTimeWithoutTimezone(start_date)}</div>
        </a>
      </div>
    )
  }
}

// PropTypes
OperatorEventSubItem.propTypes = {
  eventSub: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired
}

// Styles
const itemStyle = {
  display: 'block',
  marginBottom: '15px',
  paddingTop: '4px',
  paddingBottom: '4px',
  backgroundColor: '#1d2434',
  color: 'white',
}

const colStyle = {
  paddingLeft: '8px',
  paddingRight: '8px'
}

const nameStyle = {
  height: '1.95rem',
  fontSize: '0.7rem',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}

const roomStyle = {
  color: '#ffc800',
  fontSize: '0.8rem',
  textTransform: 'uppercase',
  marginTop: '5px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical'
}

const infoStyle = {
  fontSize: '0.8rem',
}

export default OperatorEventSubItem
