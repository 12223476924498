import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getDate } from '../../common/helpers';
import OperatorEventSubItem from './OperatorEventSubItem';
import { Card } from 'react-bootstrap';
class OperatorEventSubList extends Component {
  render() {
    if (this.props.event.sub_event) {
      const sub_events = this.props.event.sub_event;
      return (
        <>
          {this.props.event.uniqueDates.map((dates, i) => (
            <Card style={cardStyle} key={i}>
              <Card.Header style={cardHeaderStyle}>
                {getDate(dates.date)}
              </Card.Header>
              <Card.Body>
                <div className="row">
                  {sub_events.map(eventSub => {
                    return getDate(eventSub.start_date) === getDate(dates.date) ?
                      <OperatorEventSubItem key={eventSub.id} eventSub={eventSub} event={this.props.event} /> :
                      null;
                  })}
                </div>
              </Card.Body>
            </Card>
          ))
          }
        </>
      );
    } else {
      return null;
    }
  }
}

//PropTypes
OperatorEventSubList.propTypes = {
  event: PropTypes.object.isRequired,
}

//Style
const cardStyle = {
  marginBottom: '15px',
  backgroundColor: '#494d57',
  borderRadius: '10px'
}

const cardHeaderStyle = {
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  padding: '8px',
  backgroundColor: '#1d2434',
  color: 'white',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}


export default OperatorEventSubList;