// ! Please keep this packages in the first lines
import "core-js/stable";
import "regenerator-runtime/runtime";

import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import EventList from './components/event/EventList';
import AuthLogin from './components/auth/AuthLogin';
import AuthLoginOperator from './components/auth/AuthLoginOperator';
import OperatorEventList from './components/operator/OperatorEventList';
import OperatorScan from './components/operator/OperatorScan';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Route
            exact
            path="/events"
            render={props => (
              <EventList />
            )}
          />
          <Route
            path="/login"
            render={props => (
              <AuthLogin />
            )}
          />
          <Route
            exact
            path='/operator' component={() => {
              window.location.href = process.env.REACT_APP_OPERATOR_LOGIN_URL;
              return null;
            }} />
          <Route
            path="/operator/login"
            render={props => (
              <AuthLoginOperator />
            )}
          />
          <Route
            exact
            path="/operator/events"
            render={props => (
              <OperatorEventList />
            )}
          />
          <Route
            exact
            path="/operator/scan/:code/:id"
            render={props => (
              <OperatorScan {...props} />
            )}
          />
        </div>
      </Router>
    );
  }
}

export default App;
