import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { IsEmpty } from '../../../common/helpers';

export default function ModalImages(props) {
  return (
    <>
      <Modal show={props.imagesShowModal} onHide={props.imagesHandleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={modalImagesTitleStyle}>Size Chart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!IsEmpty(props.selectedEventSubName) ? (
              <>
                {props.selectedEventSubName
                  .toUpperCase()
                  .startsWith("ANAK") ? (
                    <a href="https://cf-assets.gms.church/395078fa-4446-4c44-825e-053c047a0f00/512"><img className='img-fluid mb-5' src="https://cf-assets.gms.church/395078fa-4446-4c44-825e-053c047a0f00/512" alt="Size Kids" /></a>
                  ) : (
                    <>
                      <a href="https://cf-assets.gms.church/23943a99-1f33-4907-5eb2-d56c4db22700/512"><img className='img-fluid mb-5' src="https://cf-assets.gms.church/23943a99-1f33-4907-5eb2-d56c4db22700/512" alt="Size Men" /></a>
                      <a href="https://cf-assets.gms.church/f97f2f9f-a02c-404d-0007-7ef824826500/512"><img className='img-fluid mb-5' src="https://cf-assets.gms.church/f97f2f9f-a02c-404d-0007-7ef824826500/512" alt="Size Female" /></a>
                    </>
                  )}
              </>
            ) : null}
          {/* <a href="https://cf-assets.gms.church/399d0d02-aec3-4daa-6f22-fd8617fe0600/512"><img className='img-fluid mb-5' src="https://cf-assets.gms.church/399d0d02-aec3-4daa-6f22-fd8617fe0600/512" alt="Size" /></a> */}
          {/* <a href="https://cf-assets.gms.church/9c7c2f1a-fe40-4f7b-d9c9-c5f148975300/512"><img className='img-fluid mb-5' src="https://cf-assets.gms.church/9c7c2f1a-fe40-4f7b-d9c9-c5f148975300/512" alt="Size Men" /></a>
          <a href="https://cf-assets.gms.church/30d56405-e914-4a67-1e04-4df93d688500/512"><img className='img-fluid mb-5' src="https://cf-assets.gms.church/30d56405-e914-4a67-1e04-4df93d688500/512" alt="Size Women" /></a>
          <a href="https://cf-assets.gms.church/abcc0903-f6b4-4261-3614-4fdd48a16800/512"><img className='img-fluid mb-5' src="https://cf-assets.gms.church/abcc0903-f6b4-4261-3614-4fdd48a16800/512" alt="Size Kids" /></a> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.imagesHandleClose}>Close</Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

// Styles
const modalImagesTitleStyle = {
  color: '#555',
  fontSize: '0.75rem',
  fontWeight: 'bold'
}
