import moment from 'moment';
import { churchId } from './constants';

export function changeTimezone(_date) {
    const offset = new Date().getTimezoneOffset() * -1;
    const dateNew = moment(_date).utcOffset(offset).format();
    return dateNew;
}

export function getDate(_date) {
    return moment(changeTimezone(_date)).format("D MMMM YYYY");
}

export function getTime(_date) {
    return moment(changeTimezone(_date)).format("HH:mm");
}

export function getDateWithoutTimezone(_date) {
    return moment(_date).format("dddd, D MMMM YYYY");
}

export function getTimeWithoutTimezone(_date) {
    const offset = new Date().getTimezoneOffset() * -1;
    const offsetHour = offset / 60;
    const isSingapore = (localStorage.getItem('church_id') === churchId.Singapore) ? true : false;

    let offsetGMT;
    if (isSingapore) {
        if (offsetHour === 8) {
            offsetGMT = "SGT";
        } else {
            offsetGMT = "(GMT" + (offset > 0 ? "+" : "-") + offsetHour + ")";
        }

    } else {
        if (offsetHour === 7) {
            offsetGMT = "WIB";
        } else if (offsetHour === 8) {
            offsetGMT = "WITA";
        } else if (offsetHour === 9) {
            offsetGMT = "WIT";
        } else {
            offsetGMT = "(GMT" + (offset > 0 ? "+" : "-") + offsetHour + ")";
        }
    }

    return moment(_date).format("HH:mm") + " " + offsetGMT;
}

export const IsEmpty = (obj) => {
    if (obj === null || obj === undefined || typeof obj === 'undefined' || obj === '' || obj === 'undefined' || obj === 'null') {
        return true;
    } else if (Array.isArray(obj) && obj.length === 0) {
        return true;
    } else {
        return false;
    }
};