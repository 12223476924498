import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventSubItem from './EventSubItem';
import { filter } from 'lodash';
class EventSubList extends Component {

  render() {
    if (this.props.event.sub_event) {
      let sub_events_date = filter(this.props.event.sub_event, { service_date: this.props.event.uniqueDates[this.props.selectedDate].date });
      let sub_events = [];
      sub_events_date.forEach((part, index) => {
        if (sub_events.map(e => { return e.name }).indexOf(part.name) === -1) {
          sub_events.push(part);
        }
      });

      return sub_events.map((eventSub) => (
        <EventSubItem key={eventSub.id} eventSub={eventSub} event={this.props.event} selectEvent={this.props.selectEvent} />
      ));
    } else {
      return null;
    }
  }
}

// PropTypes
EventSubList.propTypes = {
  event: PropTypes.object.isRequired,
  selectEvent: PropTypes.func.isRequired,
}

export default EventSubList;