import { churchId } from '../../common/constants'

export const setLang = (dispatch, userChurchId = localStorage.getItem('church_id')) => {
  let lang;
  userChurchId === churchId.Singapore || userChurchId === churchId.Sydney ? lang = 'en' : lang = 'ind';
  dispatch({
    type: 'SET_LANG',
    payload: {
      language: lang,
    }
  })
}