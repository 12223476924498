import React, { Component } from 'react';
import axios from 'axios';
import OperatorEventSubList from './OperatorEventSubList';
import { changeTimezone, getDateWithoutTimezone } from '../../common/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { IsEmpty } from '../../common/helpers';
class OperatorEventList extends Component {
  state = {
    events: [],
    selectedEvent: 0,
    message: '',
    isLoading: true,
    activeTab: 0,
  };

  async componentDidMount() {
    let urlEvent = `${process.env.REACT_APP_API_URL}events?is_operator=1`;

    await axios({
      method: 'GET',
      url: urlEvent,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('operator_token')}`
      }
    })
      .then(res => {
        if (res.data.success) {
          this.setState({ events: res.data.data })
        }
      }).catch(err => {
        err.response.status === 500 || err.response.status !== 404
          ? this.setState({ message: "There's an error while fetching regular events" })
          : this.setState({ message: "Tidak ada Event yang sedang berlangsung" })
      })

    this.setState({ isLoading: false })
  }

  render() {
    if (!this.state.isLoading && !IsEmpty(this.state.events)) {
      this.state.events.forEach((part, index) => {
        part.uniqueDates = [];
        part.sub_event.forEach((_part, _index) => {
          _part.start_date = changeTimezone(_part.start_date);
          _part.end_date = changeTimezone(_part.end_date);
          _part.service_date = getDateWithoutTimezone(_part.start_date)
          if (part.uniqueDates.map((e) => { return e.date; }).indexOf(_part.service_date) === -1) {
            let uniqueDate = [];
            uniqueDate.date = _part.service_date;
            part.uniqueDates.push(uniqueDate);
          }
        });

        part.uniqueDates.forEach((_part, _index) => {
          _part.uniqueRooms = [];
          part.sub_event.forEach((__part, __index) => {
            if (_part.date === __part.service_date && _part.uniqueRooms.indexOf(__part.room) === -1) {
              _part.uniqueRooms.push(__part.room);
            }
          });
        });
      });
    }

    return (
      <>
        {
          !this.state.isLoading && !IsEmpty(this.state.events) ? (
            <>
              <div className="container-fluid no-padding">
                <div style={headerStyle} className="text-center mt-3">
                  GMS Event Operator
                </div>

                <div className="row" style={{ justifyContent: 'center' }}>
                  <div className="col-sm-8 col-12">

                    {/* Select Sub-event */}
                    <div className='d-flex align-items-center justify-content-center'>
                      <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: '10px' }} />
                      <select value={this.state.selectedEvent} onChange={e => this.setState({ selectedEvent: e.target.value })} style={selectServiceStyle}>
                        {this.state.events.map((event, index) =>
                          <option key={index} value={index}>
                            {event.exclusive !== null ? `[${event.exclusive.toUpperCase()}] ` : null}{event.name}
                          </option>
                        )}
                      </select>
                    </div>

                    {/* Sub-event Item List */}
                    <div style={eventListStyle}>
                      <OperatorEventSubList event={this.state.events[this.state.selectedEvent]} />
                    </div>

                  </div>
                </div>
              </div>
            </>
          ) : (!this.state.isLoading && !IsEmpty(this.state.message) ? (
            //* Show message if event empty>
            <div className="mt-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h6>{this.state.message}</h6>
            </div>
          ) : (
            //* Show Spinner while loading
            <div className="mt-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spinner animation='border' />
            </div>
          ))
        }
      </>
    );
  }
}

const headerStyle = {
  color: '#333',
  marginBottom: '30px',
  textTransform: 'uppercase',
  fontWeight: 'bold'
}

const selectServiceStyle = {
  width: '85%',
  border: 'none',
  borderBottom: 'solid 1px #aaa',
  backgroundColor: 'transparent',
}

const eventListStyle = {
  marginLeft: '3%',
  marginRight: '3%',
  marginTop: '30px',
}

export default OperatorEventList;