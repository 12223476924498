import React, { Component } from 'react'
import axios from 'axios';
import QrReader from 'react-qr-reader'
import { getTime } from '../../common/helpers';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

let eventId
let headers
const CustomAlert = withReactContent(Swal)
const Toast = CustomAlert.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
})

class OperatorScan extends Component {

  state = {
    event: {},
    isScan: false,
    alertShowModal: false,
    isLoading: true,
    isSubmitting: false,
  };

  async componentDidMount() {
    eventId = Number(this.props.match.params.id)
    headers = {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('operator_token')
    }

    await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}operator/eventDetails?event_code=${this.props.match.params.code}&sub_event_id=${eventId}`,
      headers
    })
      .then(res => {
        if (res.data.success) {
          this.setState({ event: res.data.data })
        } else {
          this.setState({ alertShowModal: true, alertMessage: "Tidak ada informasi Event yang sedang berlangsung" });
        }
      }).catch(res => {
        this.setState({ alertShowModal: true, alertMessage: "Tidak ada Event yang sedang berlangsung" });
      })

    //* Clearup ticket code that stored to local storage
    localStorage.removeItem('ticketCode')
    this.setState({ isLoading: false })
  }


  handleScan = (data) => {
    if (!this.state.isScan && data && data !== localStorage.getItem('ticketCode')) {

      //* Temporary store ticket code to local storage to prevent double scan 
      localStorage.setItem('ticketCode', data);
      setTimeout(() => {
        localStorage.removeItem('ticketCode')
      }, 3000);

      this.setState({ isScan: true, data: null })

      axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + 'operator/check?booking_code=' + data,
        headers
      })
        .then(res => {
          if (res.data.success) {
            if (res.data.data.sub_event.id === eventId) {
              this.setState({ data: res.data.data, ticket: res.data.data.quantity });
            } else {
              Toast.fire({
                icon: 'warning',
                title: 'Tidak sesuai jadwal / ruangan yang dipilih!'
              })
            }
          } else {
            Toast.fire({
              icon: 'warning',
              title: 'Kode Salah, Silahkan Scan Ulang!'
            })
          }
          this.setState({ isScan: false })
        }).catch(err => {
            
            const unixTimestamp = err.response.data.timeData; 

            const date = new Date(unixTimestamp * 1000); 

            const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true,
                timeZone: localTimezone,
              };

            const formattedDate = date.toLocaleString('en-US', options);

          if (err.response) {
            Toast.fire({
              icon: 'error',
              title: `${err.response.data.message} ` + formattedDate
            })
          }
          this.setState({ isScan: false })
        })
    }
  }

  handleError = err => {
    Toast.fire({
      icon: 'error',
      title: `${err.message}`
    })
  }

  onClick = (e) => this.setState({ ticket: Number(e.target.value) });

  onSubmit = () => {
    this.setState({isSubmitting : true})
    axios({
      headers,
      method: 'POST',
      url: process.env.REACT_APP_API_URL + 'operator/checkout',
      data: {
        'booking_code': this.state.data.code,
        'quantity': this.state.ticket
      },
    })
      .then(res => {
        this.setState({ data: null });
        if (!res.data.success) {
          Toast.fire({
            icon: 'warning',
            title: 'Terjadi kesalahan saat checkout'
          })
        } else {
          Toast.fire({
            icon: 'success',
            title: 'Proses Checkout Berhasil'
          })
        }
        this.setState({ isSubmitting: false });
      })
      .catch(err => {
        Toast.fire({
          icon: 'error',
          title: `${err.response.data.message}`
        })
        this.setState({ isSubmitting: false });
      })
  }

  render() {
    const alertHandleClose = () => {
      this.setState({ alertShowModal: false });
    }

    if (this.state.event.name) {
      if (this.state.data) {
        let TicketButton = () => {
          return <div style={ticketConfirmStyle}>{Array.from(Array(this.state.data.quantity), (e, i) => {
            return <input
              key={i}
              type="button"
              value={i + 1}
              className={"btn " + (this.state.ticket === (i + 1) ? '' : 'lowlight') + " " + (((i + 1) % 3) === 0 ? 'btn-warning' : (((i + 1) % 2) === 0 ? 'btn-primary' : 'btn-danger'))}
              style={ticketButtonStyle}
              onClick={this.onClick}
            />
          })}
          </div>
        }
        let CheckExtras= () => {
          if (this.state.data.extras != null) {
            if(this.state.event.main_event.exclusive === "aogfindingmajor"){
              return <p style={bookingStyle}>Kelas: {JSON.parse(JSON.parse(this.state.data.extras)).kelas}</p>
            }
            // else if(this.state.event.main_event.exclusive === "aogbootcampyouth" || this.state.event.main_event.exclusive === "aogbootcampteen"){
            //   const ExtrasData=JSON.parse(this.state.data.extras)
            //   return <p style={bookingStyle}>
            //     Nama: {ExtrasData.name}
            //   </p>
              // if(this.state.data.extras.isCG){
              //   return <p style={bookingStyle}>
              //     Nama: {ExtrasData.name}
              //     Jenis Kelamin: {ExtrasData.gender}
              //     Nomor telepon: {ExtrasData.phoneNumber} {this.state.data.extras.parentPhone ? "Nomor telepon orang tua" : ""}
              //     Kode CG: {ExtrasData.cgCode}
              //     Posisi di CG: {ExtrasData.positionCG}
              //     Alergi dan Riwayat penyakit: {ExtrasData.medicalCondition}
              //     </p>
              // }
              // else{
              //   return <p style={bookingStyle}>
              //     Nama: {ExtrasData.name}
              //     Jenis Kelamin: {ExtrasData.gender}
              //     Nomor telepon: {ExtrasData.phoneNumber} {this.state.data.extras.parentPhone ? "Nomor telepon orang tua" : ""}
              //     Alamat: {ExtrasData.address}
              //     Alergi dan Riwayat penyakit: {ExtrasData.medicalCondition}
              //     </p>
              // }
            // }else if(this.state.event.main_event.exclusive === "gmsrunbalikpapan"){
            //   const ExtrasData=JSON.parse(this.state.data.extras)
            //   return <p style={bookingStyle}>
            //     Type: {ExtrasData.type}
            //     Jersey Size: {ExtrasData.size}
            //   </p>
            // }
            return <></>
          }
          return <></>
        }
        return (
          <>
            <p style={headerStyle}>{this.state.event.name}, {getTime(this.state.event.broadcast_end_date)} </p>
            <p style={bookingStyle}>{this.state.data.sub_event.room.name}</p>
            <p style={bookingStyle}>{this.state.data.participant.name}, {this.state.data.quantity} Tiket</p>
            <p style={confirmStyle}>Jumlah tiket yang akan diproses</p>
            <CheckExtras/>
            <TicketButton />
            <input type="button" className="btn btn-success" style={submitStyle} value={this.state.isSubmitting ? "Submiting..." : "Submit"} onClick={this.onSubmit} disabled={this.state.isSubmitting ? true : false}/>
          </>
        )
      } else {
        return (
          <>
            <div className="container-fluid no-padding">
              <QrReader
                delay={1000}
                onError={this.handleError}
                onScan={this.handleScan}
                showViewFinder={false}
                style={{ ...qrReaderStyle, border: `solid 7px #${this.state.event.ticket_color_hex}` }}
              />
              <p style={infoStyle}>Scan QR Code dengan Kamera Anda</p>
            </div>

            <div className='fixed-bottom' style={footerScannerStyle}>
              <p style={{ marginBottom: 0, fontWeight: 'bold' }}>{this.state.event.main_event.name} - {this.state.event.name}</p>
              <p style={{ marginBottom: 0 }}>{this.state.event.room.name}</p>
            </div>

            {/* Alert Modal */}
            <Modal show={this.state.alertShowModal} onHide={alertHandleClose}>
              <Modal.Header closeButton>
                <Modal.Title style={modalAlertTitleStyle}>Peringatan</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p style={modalAlertStyle}>{this.state.alertMessage}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={alertHandleClose}>OK</Button>
              </Modal.Footer>
            </Modal>
          </>
        )
      }
    } else {
      return null;
    }
  }
}

//Styles
const footerScannerStyle = {
  backgroundColor: '#1d2434',
  color: '#fff',
  textAlign: 'center',
  padding: '7px',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px'
}

const qrReaderStyle = {
  width: '80%',
  marginLeft: '10%',
  marginTop: '60px',
  borderRadius: '5%',
}

const ticketButtonStyle = {
  padding: '2px 8px',
  marginRight: '8px',
  flex: '1',
  fontSize: '1.5rem',
  paddingTop: '20px',
  paddingBottom: '20px'
}

const infoStyle = {
  marginTop: '30px',
  textAlign: 'center'
}

const headerStyle = {
  marginLeft: '20px',
  marginRight: '20px',
  marginTop: '60px',
  marginBottom: '0px',
  fontWeight: 'bold',
  fontSize: '1.2rem'
}

const bookingStyle = {
  marginLeft: '20px',
  marginRight: '20px',
  marginTop: '4px',
  fontSize: '1.0rem'
}

const confirmStyle = {
  marginLeft: '20px',
  marginRight: '20px',
  marginTop: '24px',
  fontSize: '1.0rem',
  marginBottom: '40px'
}

const ticketConfirmStyle = {
  marginLeft: '20px',
  marginRight: '20px',
  marginBottom: '40px',
  display: 'flex'
}

const submitStyle = {
  width: '100%',
  position: 'absolute',
  bottom: '0',
  paddingTop: '20px',
  paddingBottom: '20px',
  textTransform: 'uppercase',
  fontSize: '1.3rem'
}

const modalAlertTitleStyle = {
  color: '#555',
  fontSize: '0.75rem',
  fontWeight: 'bold'
}

const modalAlertStyle = {
  fontSize: '0.8rem',
}

export default OperatorScan;
