import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDateWithoutTimezone, getTimeWithoutTimezone } from '../../common/helpers';

export class EventSubItem extends Component {
  render() {
    // eslint-disable-next-line
    const { id, name, start_date, end_date, quantity, price } = this.props.eventSub;
    return (
      <div style={divStyle}>
        <button
          className="btn btn-outline-secondary btn-block btn-select text-left"
          onClick={this.props.selectEvent.bind(this, this.props.event, this.props.eventSub)}
          style={buttonStyle}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-2" style={{ padding: '0' }}>
                <div style={{ ...calendarStyle, backgroundColor: `#${this.props.eventSub.ticket_color_hex}` }}>
                  <FontAwesomeIcon icon={faCalendar} style={{ width: '100%', height: '50%', marginTop: '30%', color: 'white', textAlign: 'center' }} />
                </div>
              </div>
              <div className="col-10">
                <div style={infoStyle}>{getDateWithoutTimezone(start_date)} &#x2022; {getTimeWithoutTimezone(start_date)}</div>
                <div style={titleStyle}>{name}</div>
                {this.props.event.is_paid ? (
                  <div>
                  <span style={priceStyle}>IDR {price.toLocaleString()}</span> <span style={pricePersonStyle}>/ person</span></div> 
                ) : null}
              </div>
            </div>
          </div>
        </button>
      </div>
    )
  }
}

// PropTypes
EventSubItem.propTypes = {
  eventSub: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  selectEvent: PropTypes.func.isRequired,
}

// Styles
const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  verticalAlign: 'middle',
  fontSize: '1.0rem',
  marginBottom: '7px',
  borderRadius: '6px',
  borderColor: '#adb5bd'
}
const divStyle = {
  width: '100%'
}
const calendarStyle = {
  padding: '0',
  borderRadius: '6px',
  height: '75%',
  marginTop: '25%'
}
const infoStyle = {
  color: '#423f40',
  fontSize: '0.85rem',
}
const titleStyle = {
  fontSize: '1rem',
  fontWeight: 'bold',
  marginBottom: '18px'
}
const priceStyle = {
  fontSize: '0.9rem',
  color: '#dc3545',
}
const pricePersonStyle = {
  color: '#423f40',
  fontSize: '0.7rem',
}

export default EventSubItem
