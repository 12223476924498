import React, { Component } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { IsEmpty } from '../../common/helpers';
import { Redirect } from 'react-router';

class AuthLogin extends Component {
  state = {
    loginStatus: false,
    loginMessage: ''
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const auth_code = params.get('auth_code');
    const exclusive = params.get('exclusive');

    if (!IsEmpty(auth_code)) {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}login`,
        data: {
          'auth_code': auth_code,
        },
        headers: { 'Accept': 'application/json' }
      })
        .then(res => {
          if (res.data.success) {
            localStorage.setItem('token', res.data.data.token);
            localStorage.setItem('church_id', res.data.data.church_info.id);
            localStorage.setItem('church_name', res.data.data.church_info.name);
            localStorage.setItem('allow_register', res.data.data.allowRegister);
            localStorage.setItem('exclusive', exclusive);
            this.setState({ loginStatus: true });
          } else {
            this.setState({ loginMessage: 'Login Failed' });
          }
        }).catch(err => {
          err.response.status === 500 ?
            this.setState({ loginMessage: `There was a problem with the server\n${err.response.status}` }) :
            this.setState({ loginMessage: `There was a problem with the network\n${err.response.status}` })
        })
    } else {
      this.setState({ loginMessage: 'Login Failed' });
    }
  }

  render() {
    return (
      <div className="container mt-4 text-center">
        {this.state.loginStatus === false && IsEmpty(this.state.loginMessage) ? (
          <Spinner animation='border' />
        ) : (
          <span>{this.state.loginMessage}</span>
        )}
        {this.state.loginStatus === true && <Redirect to={{ pathname: "/events" }} />}
      </div>
    )
  }
}

export default AuthLogin;