import React from "react";
import { connect } from "react-redux";
import { l10n } from "../../../common/constants";
import { Button, Modal } from "react-bootstrap";

function ModalDynamicDisclaimer(props) {
    return (
        <>
            <Modal show={props.welcomeShowModal}>
                <Modal.Header>
                    <Modal.Title>Disclaimer</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.welcomeShowModalDescription,
                        }}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <div
                        className="row d-flex align-items-center"
                        style={agreementStyle}
                    >
                        <div className="col-2">
                            <input
                                type="checkbox"
                                name="agreement"
                                value={props.agreement}
                                checked={props.agreement}
                                onChange={props.onCheckAgreement}
                                style={agreementCheckStyle}
                            />
                        </div>
                        <div
                            className="col-10"
                            onClick={props.onCheckAgreement}
                            value={props.agreement}
                        >
                            {l10n["acknowledge"][props.language]}
                        </div>
                    </div>
                    <Button
                        variant="secondary"
                        onClick={props.firstHandleReject}
                    >
                        {l10n["cancel"][props.language]}
                    </Button>
                    <Button variant="primary" onClick={props.firstHandleClose}>
                        {l10n["agree"][props.language]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

// Styles
const agreementStyle = {
    marginTop: "24px",
    marginBottom: "24px",
};

const agreementCheckStyle = {
    width: "24px",
    height: "24px",
    cursor: "pointer",
};

// Redux
const mapStateToProps = (state) => ({
    language: state.userReducer.language,
});

export default connect(mapStateToProps)(ModalDynamicDisclaimer);
