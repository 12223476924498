import React from "react";
import { useSelector } from "react-redux";
import { l10n } from "../../../common/constants";
import { Modal, Button } from "react-bootstrap";

export default function ModalAlert(props) {
    const { language } = useSelector((state) => ({
        language: state.userReducer.language,
    }));

    return (
        <>
            <Modal show={props.alertShowModal} onHide={props.alertHandleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={modalAlertTitleStyle}>
                        Peringatan
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={modalAlertStyle}>{props.alertMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.alertHandleClose}>OK</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={props.confirmationShowModal}
                onHide={props.confirmationHandleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={modalConfirmationTitleStyle}>
                        {l10n["confirmation"][language]}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={modalConfirmationStyle}>
                        {props.confirmationMessage}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={props.confirmationHandleClose}
                    >
                        {l10n["no"][language]}
                    </Button>
                    <Button onClick={props.confirmationSubmit}>
                        {l10n["yes"][language]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

// Styles
const modalAlertTitleStyle = {
    color: "#555",
    fontSize: "0.75rem",
    fontWeight: "bold",
};

const modalAlertStyle = {
    fontSize: "0.8rem",
};

const modalConfirmationTitleStyle = {
    color: "#555",
    fontSize: "0.75rem",
    fontWeight: "bold",
};

const modalConfirmationStyle = {
    fontSize: "0.8rem",
};
