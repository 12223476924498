import React, { Component } from "react";
import PropTypes from "prop-types";
import EventSubList from "./EventSubList";

export class EventItem extends Component {
    state = {
        selectedDate: 0,
    };

  render() {
    // eslint-disable-next-line
    const { code, name, image_url } = this.props.event;
    const fullImageUrl = image_url.startsWith('http') ? image_url : `${process.env.REACT_APP_IMAGES_URL}${image_url}`;
    return (
      <div className="row" style={eventItemStyle}>
        <div className="col-sm-8 col-12" style={{ paddingBottom: '50px' }}>
          {/* Image Banner */}
          <img
            className="img-fluid shadow"
            src={fullImageUrl}
            alt={name}
            style={imageStyle}
          />

          {this.props.event.uniqueDates.map((uniqueDate, index) => (
            <div key={index} style={divStyle}>
              <p style={titleStyle}>{name}</p>
              <div className="row" style={eventSubStyle}>
                <EventSubList
                  event={this.props.event}
                  selectedDate={index}
                  selectEvent={this.props.selectEvent}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
    }
}

// PropTypes
EventItem.propTypes = {
    event: PropTypes.object.isRequired,
    selectEvent: PropTypes.func.isRequired,
};

// Styles
const divStyle = {
  marginLeft: '0%',
  marginBottom: '25px',
};

const imageStyle = {
    marginBottom: "25px",
    borderRadius: "10px",
};

const titleStyle = {
  fontWeight: 'bold',
  marginBottom: '12px',
  marginLeft: '0%',
  fontSize: '1.5rem',
};

// const selectDateStyle = {
//   width: '85%',
//   fontSize: '1.00rem',
//   border: 'none',
//   paddingBottom: '4px',
//   marginTop: '5px',
//   marginLeft: '5px',
//   borderBottom: '1px solid #ccc',
//   backgroundColor: 'transparent',
// };

const eventSubStyle = {
  width: '100%',
  marginLeft: '0%',
  marginTop: '5px',
};

const eventItemStyle = {
    justifyContent: "center",
    marginBottom: "5px",
};
export default EventItem;
